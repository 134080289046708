import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Monitor from '../views/Monitor.vue'
import ErrorPage from '../views/ErrorPage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/monitor',
      component: Monitor
    },
    {
      path: '/error', // Define error route
      name: 'ErrorPage',
      component: ErrorPage,
      props: (route) => ({ errorMsg: route.params.errorMsg })
    }
  ]
})

export default router
