
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Navbar',
  props: {
    isSupervisor: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      collapsed: false
    }
  },
  methods: {
    toggleMenu () {
      this.collapsed = !this.collapsed
    }
  }
})
