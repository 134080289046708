
import genesyscloudService from '@/services/genesyscloud-service'
import platformClient from 'purecloud-platform-client-v2'
import Spinner from '@/components/Spinner.vue'
import { defineComponent } from 'vue'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default defineComponent({
  name: 'TransferModal',
  components: { Spinner },
  props: ['showModal', 'closeTransferModal', 'conversationId', 'participantId', 'multipleSelection', 'itemsSelected', 'queues', 'getConversationDetails', 'queue', 'hideReload'],
  data () {
    return {
      isItemLoading: false,
      showAgents: true,
      searchTermAgent: '',
      searchTermQueue: '',
      selectedObjectType: '',
      tabSelected: 1,
      users: [] as platformClient.Models.User[],
      selectedUser: { name: '', id: '' },
      selectedQueue: { name: '', id: '' }
    }
  },
  // watch: {
  //   selectedQueueWaiting () {
  //     this.isItemLoading = true
  //     setTimeout(() => {
  //       this.getConversationDetails(this.queue)
  //       this.isItemLoading = false
  //     }, 2000)
  //   }
  // },
  inject: ['notyf'],
  methods: {
    resetData () {
      this.selectedUser = { name: '', id: '' }
      this.selectedQueue = { name: '', id: '' }
      this.showAgents = true
      this.tabSelected = 1
      this.selectedObjectType = ''
      this.searchTermAgent = ''
      this.searchTermQueue = ''
    },
    selectTab (tab: number) : void {
      if (tab !== this.tabSelected) {
        this.tabSelected = tab
        this.showAgents = !this.showAgents
      }
    },
    setUser (user: platformClient.Models.User) {
      const { name, id } = user || {}
      if (name && id) {
        this.selectedUser.name = name || ''
        this.selectedUser.id = id || ''
        this.selectedQueue = { name: '', id: '' }
        this.selectedObjectType = 'user'
      }
    },
    setQueue (queue: platformClient.Models.Queue) {
      const { name, id } = queue || {}
      if (name && id) {
        this.selectedQueue.name = name || ''
        this.selectedQueue.id = id || ''
        this.selectedUser = { name: '', id: '' }
        this.selectedObjectType = 'queue'
      }
    },
    async fetchUsers () {
      try {
        const res = await genesyscloudService.getAllUsers()
        if (res) {
          this.users = res.sort((a, b) => {
            return !a.name ? -1 : !b.name ? 1 : a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async transfer () {
      const notyf = this.notyf as Notyf
      this.isItemLoading = true
      try {
        let requests
        if (!this.multipleSelection) {
          requests = [genesyscloudService.replaceParticipant(this.conversationId, this.participantId, this.selectedObjectType, this.selectedObject)]
        } else {
          requests = this.itemsSelected.map((el: { conversationId: string, participantId: string}) => genesyscloudService.replaceParticipant(el.conversationId, el.participantId, this.selectedObjectType, this.selectedObject))
        }

        await Promise.all(requests)
        this.closeTransferModal()
        notyf.success('Transfert réussi')
      } catch (error) {
        notyf.error('Une erreur s\'est produite')
        console.log(error)
      } finally {
        setTimeout(() => {
          this.getConversationDetails(this.queue)
          this.isItemLoading = false
          this.hideReload()
        }, 5000)
      }
    //   this.isItemLoading = true
    //   try {
    //     await genesyscloudService.replaceParticipant(this.conversationId, this.participantId, this.selectedUser.id)
    //   } catch (error) {
    //     console.log(error)
    //   } finally {
    //     this.closeTransferModal()
    //   }
    //   this.isItemLoading = false
    }
  },
  created () {
    this.fetchUsers()
  },
  computed: {
    filteredAgents () {
      return this.users.filter((user: platformClient.Models.User) => user.name && user.name.toLowerCase().includes(this.searchTermAgent.toLowerCase())
      )
    },
    filteredQueues () {
      return this.queues.filter((queue: platformClient.Models.User) => queue.name && queue.name.toLowerCase().includes(this.searchTermQueue.toLowerCase())
      )
    },
    selectedItemsNumber () {
      return this.itemsSelected.length
    },
    selectedObject () {
      if (this.selectedUser.name && this.selectedUser.id) {
        return this.selectedUser.id
      }
      if (this.selectedQueue.name && this.selectedQueue.id) {
        return this.selectedQueue.id
      }

      return ''
    }
  }
})
