
import genesysCloudService from '@/services/genesyscloud-service'
import platformClient from 'purecloud-platform-client-v2'
import { defineComponent } from 'vue'
import { Notyf } from 'notyf'
import { useI18n } from 'vue-i18n'
import config from '@/config/config'
import { getClientApp } from '@/services/client-app-service'

export default defineComponent({
  name: 'ReplyModal',
  props: [
    'showModal', 'closeReplyModal', 'queueId', 'externalContactId', 'queueName', 'html', 'subject', 'to', 'conversationId', 'from'
  ],
  setup () {
    const { t } = useI18n() // Access the `t` function from `useI18n`

    return {
      t
    }
  },
  data () {
    return {
      queuesLoading: false,
      showQueues: false,
      isQueueRemoved: false,
      searchQueue: '',
      selectedQueuename: '',
      timeout: 0,
      queues: [] as platformClient.Models.UserQueue[],
      internalQueueId: this.queueId
    }
  },
  inject: ['notyf'],
  watch: {
    showModal (newVal) {
      if (newVal) {
        this.internalQueueId = this.queueId
        this.selectedQueuename = this.queueName
      }
    }
  },
  methods: {
    debounce () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => this.getQueues(), 1000)
    },
    async getQueues () {
      if (this.searchQueue.length > 0) {
        this.queuesLoading = true
        const queues = await genesysCloudService.getQueuesMe()
        if (queues) {
          this.queues = queues
            .filter(queue => queue.id && queue.id !== this.internalQueueId)
            .filter(queue => queue.name?.toLowerCase().includes(this.searchQueue.toLowerCase()))
        }
        this.queuesLoading = false
        this.showQueues = this.queues.length > 0
      } else {
        this.showQueues = false
        this.queues = []
      }
    },
    resetData () {
      this.isQueueRemoved = false
      this.showQueues = false
      this.searchQueue = ''
      this.internalQueueId = ''
    },
    setQueue (queue: platformClient.Models.UserQueue) {
      this.searchQueue = queue.name || ''
      this.selectedQueuename = queue.name || ''
      this.internalQueueId = queue.id || ''
      this.isQueueRemoved = false
      this.showQueues = false
    },
    removeQueue () {
      this.internalQueueId = ''
      this.isQueueRemoved = true
    },
    triggerFileUpload () {
      const fileInput = this.$refs.fileInput as HTMLInputElement
      if (fileInput) {
        fileInput.click()
      }
    },
    async runAction () {
      const notyf = this.notyf as Notyf
      try {
        await genesysCloudService.postConversationsEmailReconnect(this.conversationId)
      } catch (error) {
        if (error.status === 400 && error.code === 'email.reconnect.no.internal') {
          console.log('no previous agent communication')
          notyf.error(this.t('no_previous_agent_communication'))
        } else {
          notyf.error(this.t('error_occurred'))
        }
      } finally {
        try {
          await genesysCloudService.getConversationsEmailMessagesDraft(this.conversationId)
          this.triggerGenesysAlertWithAction(
            this.t('interaction_reopened'),
            this.t('email_draft_created'),
            'info'
          )
        } catch (draftError) {
          // Handle error while getting the draft
          console.error('Error during draft creation:', draftError)
          notyf.error(this.t('error_creating_draft'))
        } finally {
          this.closeReplyModal()
        }
      }
    },

    triggerGenesysAlertWithAction (
      title: string,
      message: string,
      type: 'info' | 'success' | 'error' = 'info',
      callback?: () => void
    ) {
      const myClientApp = getClientApp()
      if (myClientApp && myClientApp.alerting) {
        myClientApp.alerting.showToastPopup(
          title, // Title of the toast
          message, // Message content
          { type: type, timeout: 5000 } // Options (type and duration)
        )
        if (callback) {
          setTimeout(() => {
            callback()
          }, 5000)
        }
      }
    }
  },
  computed: {
    queueResult () {
      return this.queues.length === 0
    }
  }
})
