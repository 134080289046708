
import { defineComponent } from 'vue'
import FAQ from './FAQ.vue'
import config from '@/config/config'

export default defineComponent({
  name: 'EmailBodyModal',
  props: ['showModal', 'closeEmailBodyModal', 'toggleOpen', 'emailBodies', 'from', 'converationId'],
  components: { FAQ },
  methods: {
    viewInteraction () {
      const url = `https://apps.${config.pcEnvironment}/directory/#/engage/admin/interactions/${this.converationId}`
      window.open(url)
    }
  }
})
