import ClientApp from 'purecloud-client-app-sdk'

let myClientApp: ClientApp | null = null

export function initializeClientApp (pcEnvironment: string): ClientApp {
  if (!myClientApp) {
    myClientApp = new ClientApp({
      pcEnvironment
    })
  }
  return myClientApp
}

export function getClientApp (): ClientApp | null {
  return myClientApp
}
