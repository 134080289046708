import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VCalendar from 'v-calendar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMobile, faAddressCard, faMessage, faCommentSms, faUserPlus, faArrowRight, faTriangleExclamation, faCircleCheck, faCircleXmark, faAngleDoubleLeft, faAngleDoubleRight, faHome, faSignal5, faGear, faCircleInfo, faEye, faBan, faHand, faRotate, faRightLeft, faLock, faFilter, faReply, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import print from 'vue3-print-nb'
import VueClickAway from 'vue3-click-away'
import { createI18n } from 'vue-i18n'
import en from './locales/en-us.json'
// Import the localization messages
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: 'right',
    y: 'top'
  },
  dismissible: true
})
// Configure i18n
const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages: { en }
})
library.add(faMobile, faAddressCard, faMessage, faCommentSms, faUserPlus, faArrowRight, faTriangleExclamation, faCircleCheck, faCircleXmark, faAngleDoubleLeft, faAngleDoubleRight, faHome, faSignal5, faGear, faCircleInfo, faEye, faBan, faHand, faRotate, faRightLeft, faLock, faFilter, faReply, faClock)
createApp(App)
  .use(router)
  .use(VueClickAway)
  .use(print)
  .use(i18n)
  .component('fa', FontAwesomeIcon)
  .component('calendar', VCalendar)
  .component('EasyDataTable', Vue3EasyDataTable)
  .provide('notyf', notyf)
  .mount('#app')
