import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7001d645"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-heading" }
const _hoisted_2 = { class: "faqs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FAQ = _resolveComponent("FAQ")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", {
      class: "overlay",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeEmailBodyModal && _ctx.closeEmailBodyModal(...args)))
    }, null, 512), [
      [_vShow, _ctx.showModal]
    ]),
    _createElementVNode("section", {
      class: _normalizeClass(["modal", { show: _ctx.showModal}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t('modal.from')) + ": ", 1),
          _createTextVNode(_toDisplayString(_ctx.from), 1)
        ]),
        _createElementVNode("span", {
          class: "closing__icon",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeEmailBodyModal && _ctx.closeEmailBodyModal(...args)))
        }, "✕")
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emailBodies, (emailBody, i) => {
          return (_openBlock(), _createBlock(_component_FAQ, {
            emailBody: emailBody,
            index: i,
            key: i,
            open: emailBody.open,
            onToggleOpen: _ctx.toggleOpen
          }, null, 8, ["emailBody", "index", "open", "onToggleOpen"]))
        }), 128))
      ]),
      _createElementVNode("button", {
        class: "btn",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.viewInteraction && _ctx.viewInteraction(...args)))
      }, _toDisplayString(_ctx.$t('modal.openGenesys')), 1)
    ], 2)
  ], 64))
}