import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "monitor" }
const _hoisted_2 = { class: "dashboard-container" }
const _hoisted_3 = { class: "header-title" }
const _hoisted_4 = { class: "datatable-container" }
const _hoisted_5 = { class: "spinner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EasyDataTable = _resolveComponent("EasyDataTable")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('monitor.headerTitle')), 1)
      ]),
      _createVNode(_component_EasyDataTable, {
        headers: _ctx.headers,
        items: _ctx.queues.map(queue => ({
        ...queue,
        displayOWaiting: queue.oWaiting === 0 ? '-' : queue.oWaiting,
        displayOInteracting: queue.oInteracting === 0 ? '-' : queue.oInteracting
      })),
        "table-class-name": "dashboard-table",
        "empty-message": _ctx.$t('monitor.emptyMessage'),
        "rows-per-page-message": _ctx.$t('monitor.rowsPerPageMessage'),
        onClickRow: _ctx.getConversationDetails
      }, null, 8, ["headers", "items", "empty-message", "rows-per-page-message", "onClickRow"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_DataTable, {
        ref: "childComponent",
        queuename: _ctx.queuename,
        conversations: _ctx.conversations,
        wrapupCodes: _ctx.wrapupcodes,
        queue: _ctx.queue,
        selectedQueueWaiting: _ctx.queue.oWaiting,
        selectedQueueInteracting: _ctx.queue.oInteracting,
        getConversationDetails: _ctx.getConversationDetails,
        userId: _ctx.userId,
        queues: _ctx.items
      }, null, 8, ["queuename", "conversations", "wrapupCodes", "queue", "selectedQueueWaiting", "selectedQueueInteracting", "getConversationDetails", "userId", "queues"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives(_createVNode(_component_Spinner, null, null, 512), [
        [_vShow, _ctx.isItemLoading]
      ])
    ])
  ]))
}