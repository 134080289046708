
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    switchTo: {
      type: Function,
      required: true
    },
    switchFrom: {
      type: Function,
      required: true
    },
    onSwitch: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      isDropdownOpen: false
    }
  },
  methods: {
    toggleDropdown () {
      this.isEmailAttributes && (this.isDropdownOpen = !this.isDropdownOpen)
    },
    selectOption (option: string) {
      this.displayName = option + this.displayName?.substring(3)
      this.isDropdownOpen = false
    },
    getEmailFromDisplayName (name: string) {
      if (name) {
        const emailStartIndex = name.lastIndexOf(' ') + 1
        return name.substring(emailStartIndex)
      }
    },
    toggleAttribute (isToDisplayed: boolean) {
      // this.onClose(this.name)
      this.onSwitch(this.name)
      if (isToDisplayed) {
        this.switchTo(this.getEmailFromDisplayName(this.name))
      } else {
        this.switchFrom(this.getEmailFromDisplayName(this.name))
      }
      this.isDropdownOpen = false
    },
    toggleAttributeTo () {
      // this.onClose(this.name)
      this.onSwitch(this.name)
      this.switchTo(this.getEmailFromDisplayName(this.name))
      this.isDropdownOpen = false
    },
    toggleAttributeFrom () {
      // this.onClose(this.name)
      this.onSwitch(this.name)
      this.switchFrom(this.getEmailFromDisplayName(this.name))
      this.isDropdownOpen = false
    }
  },
  computed: {
    displayName () {
      interface TranslationMap {
        [key: string]: string | { [key: string]: string };
        Queue: string;
        User: string;
        Division: string;
        From: string;
        To: string;
        Date: {
          Today: string;
          Yesterday: string;
          'This week': string;
          'Last week': string;
          'Previous 7 days': string;
          'This month': string;
          'Last month': string;
          'Previous 30 days': string;
        }
      }
      const translations: TranslationMap = {
        Queue: 'File d\'attente',
        User: 'Utilisateur',
        Division: 'Division',
        From: 'De',
        To: 'À',
        Date: {
          Today: 'Aujourd\'hui',
          Yesterday: 'Hier',
          'This week': 'Cette semaine',
          'Last week': 'La semaine dernière',
          'Previous 7 days': '7 jours précédents',
          'This month': 'Ce mois-ci',
          'Last month': 'Le mois dernier',
          'Previous 30 days': '30 jours précédents'
        }
      }
      const splitName = this.name?.split(':').map(str => str.trim()) ?? []
      const type = splitName[0]
      const value = splitName[1]

      if (translations[type]) {
        if (typeof translations[type] === 'string') {
          return `${translations[type]}: ${value}`
        } else {
          const typeObj = translations[type]
          if (typeof typeObj === 'string') {
            return `${typeObj}: ${value}`
          } else if (value && typeObj[value]) {
            return `Date: ${typeObj[value]}`
          } else {
            return `Date: ${value}`
          }
        }
      } else {
        return this.name
      }
    },
    isFromDisplayed () {
      return this.displayName?.startsWith('De: ')
    },
    isEmailAttributes () {
      return this.displayName?.startsWith('De: ') || this.displayName?.startsWith('À: ')
    }
  }
})
