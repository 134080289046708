export default {
  clientId: '',
  pcEnvironment: 'mypurecloud.com',
  langTag: '',
  redirectUri: 'https://emailfinder.dev.mentat.fr',
  redirectUri_Local: 'http://localhost:8080',
  redirectUri_Dev: 'https://emailfinder.dev.mentat.fr',
  BILLING_API_KEY: 'A2aXs8X!e42AU6+v+8YT$$i=5cHiafXnED',
  BILLING_APPLICATION_ID: '1448ba20-8e19-4a39-a83a-05574d1e23cf'
}
