
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup () {
    const route = useRoute()

    const errorMessage = computed(() => {
      return route.query.errorMsg || 'An unknown error occurred'
    })

    return {
      errorMessage
    }
  }
})
